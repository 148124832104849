.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7em 2em 6em 2em;
  overflow: hidden;
}

.body {
  max-width: 30em;
}

.steps {
  margin-top: 5em;
}

@media (max-width: 1000px) {
  .container {
    padding: 6em 2em 5em 2em;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 5em 2em 4em 2em;
  }

  .steps {
    margin-top: 3.5em;
  }
}