.container {
  display: flex;
  flex-direction: column;
  font-family: Apercu, sans-serif;
  color: var(--color-palette-3);
  position: relative;
}

.title {
  font-weight: 500;
  font-size: 2em;
}

.description {
  font-weight: 100;
  min-height: 2em;
  margin-top: 0.4em;
}

.button {
  margin-top: 1em;
  min-width: 5em;
  border: 2px solid var(--color-palette-3);
}

.badge {
  position: absolute;
  right: -2.7em;
  top: -1.8em;
}

@media (max-width: 768px) {

  .button {
    width: 10em;
    margin-top: 0.2em;
  }

  .badge {
    left: 9em;
    top: -1.6em;
  }
}
