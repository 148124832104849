.container {
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  padding: 0 6em;
  font-size: 1.6em;

  @media (max-width: 1580px) {
    padding: 0 3em;
    font-size: 1.3em;
  }

  @media (max-width: 991px) {
    padding: 0 1em;
    font-size: 1.1em;
  }

  @media (max-width: 768px) {
    padding: 0 1em;
    font-size: 0.8em;
  }
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8em;
  align-items: center;
}

.logo {
  height: 2.6em;
  width: auto;
}

.header2,
.header3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  position: relative;
  font-size: 1.1em;
  padding-top: 0.6em;
}

.table_row {
  height: 2.6em;
  width: 100%;
  box-sizing: border-box;
}

.table_row_label {
  text-transform: uppercase;
  font-size: 0.8em;
  vertical-align: top;
  line-height: 110%;
  letter-spacing: 0.02em;
  padding-top: 0.5em;

  @media (max-width: 768px) {
    display: inline-block;
    max-width:  10em;
    line-height: 130%;
    margin-top: -0.4em;
  }
}

.header1,
.background1 {
  width: 34%;
}

.table_cell {
  padding: 0 0.6em 0.6em 0.6em;
  vertical-align: top;
  text-align: center;
  height: 2.6em;
}

.table_cell,
.header2,
.header3,
.background2,
.background3 {
  width: 33%;
}

.table_cell_content {
  font-size: 0.9em;
  width: 100%;
  margin: 0 auto;
  line-height: 130%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
  max-width: 10em;
}

.primary_plan_logo {
  text-align: center;
}

.alt_provider_name {
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 0.06em;
  text-align: center;
  max-width: 10em;
  display: inline-block;
  line-height: 110%;

  @media (max-width: 768px) {
    letter-spacing: 0.03em;
  }
}

.header3 {
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
}

.alt_plan_name {
  margin-top: 0.3em;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  letter-spacing: 0.06em;
}

.alt_plan_names {
  display: flex;
  flex-direction: row;
}

.alt_plan_names :first-child {
  margin-right: 0.6em;
}

.alt_plan_name_selected {
  border-bottom: 3px solid var(--color-palette-4);
}

.secondary_plan_name .table_cell_content {
  max-width: 10em;
}

.overview_table,
.diagnostics_table,
.restoration_table,
.major_services_table,
.extras_table {
  margin-top: 0.6em;
}

.accordion {
  padding-top: 1em;
  padding-bottom: 0.9em;
  border-top: 1px solid var(--color-palette-4);
  font-size: 1em;
}

.accordion_title {
  font-size: 1.6em;

  @media (max-width: 768px) {
    font-size: 1.4em;
    max-width: 6em;
    line-height: 130%;
  }
}

.background {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 1.3em);
  z-index: -1;
}
.background2 {
  background-color: var(--color-palette-1);
}

.temp_hide_icon {
  display: none;
}

.temp_accordion_title {
  display: none;
}