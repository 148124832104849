.container {
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 22em);

  @media (max-width: 960px) {
    padding: 3em 0;
  }
}

.header {
  max-width: 26em;
  text-align: center;

  @media (max-width: 960px) {
    max-width: 18em;
  }
}

.subtitle {
  max-width: 18em;
  font-weight: 100;
  margin: -1em auto 0 auto;

  @media (max-width: 768px) {
    margin: -0.6em auto 0 auto;
  }
}

.cta {
  margin-top: 3em;
  border: 2px solid var(--color-palette-3) !important;
}

.skip {
  margin-top: 1em;
}

.cta,
.skip {
  width: 16em;
}