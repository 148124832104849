.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30em;
  margin: auto;
  padding-bottom: 3em;
}

.title {
  margin-top: 0em;
}

.subtitle {
  width: 100%;
  font-family: var(--global-font-family);
  font-weight: 100;
  font-size: 1.2em;
}

.form {
  width: 100%;
  margin-left: -0.6em;
  margin-bottom: 4em;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4.3em;
}

.submit {
  width: 11em;
}

.card {
  margin-top: 3em;
}

.card_element,
.card_expiry,
.card_cvc {
  border-bottom: 2px solid #106A5C;
  padding: 0.8em 0;
}

.card_expiry,
.card_cvc {
  width: 46% !important;
}

.card_columns {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {

  .container {
    height: auto;
    width: 80%;
  }

  .title {
    width: 9.5em;
    margin-top: 1.5em;
  }

  .subtitle {
    font-size: 1em;
  }

  .form {
    width: 100%;
    padding: 0;
    font-size: 0.8em;
  }

  .wrapper {
    text-align: left;
  }

  .submit {
    min-width: 14em !important;
  }

  .buttons {
    margin-top: 4em;
  }
}
