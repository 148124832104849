.container {
  display: flex;
  flex-direction: row;
}

.text {
  color: var(--color-palette-3);
}

.check {
  height: 0.8em;
  margin-right: 0.33em;
  margin-top: 0.08em;
}

.discount {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 0.03em;
  font-weight: 500;
}

.trailing {
  margin-left: 0.3em;
}

.intake {
  padding-bottom: 0.2em;
}

.edit {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid var(--color-palette-3);
  padding-bottom: 0.1em;
  width: 9em;
  justify-content: space-between;
}

.coupon_link {
  cursor: pointer;
  padding-bottom: 0.05em;
  border-bottom: 1px solid var(--color-palette-3);
}

.coupon_link:hover {
  opacity: 0.86;
}

.coupon_input {
  font-family: var(--global-font-family);
  font-size: 0.8em;
  width: 6em;
  outline: none;
  border: none;
  border-bottom: 0px solid var(--color-palette-3);
  padding-left: 1.2em;
  padding-right: 1.2em;
  color: var(--color-palette-3);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 1em;
  background-color: transparent;
}

.coupon_icon {
  height: 0.8em;
  width: auto;
  position: absolute;
}

.apply_button {
  background-color: transparent;
  border: 1px solid var(--color-palette-3);
  color: 1px solid var(--color-palette-3);
  border-radius: 1em;
  font-family: var(--global-font-family);
  font-size: 0.66em;
  font-weight: 300;
  padding: 0.25em 0.6em 0.2em 0.6em;
  margin-left: -1em;
  cursor: pointer;
  margin-top: -0.2em;
}

.apply_button:hover {
  opacity: 0.86;
}

.apply_button:disabled {
  opacity: 0.36;
}