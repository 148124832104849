.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 20em);
  margin: auto;
  animation: confirm-page-fade-in 1s;
}

@keyframes confirm-page-fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
} 

.title {
  text-align: center;
  max-width: 6em;
  margin: 0 auto;
}

.info {
  line-height: 160%;
}

.bold {
  font-weight: 300;
  background-color: var(--color-palette-1);
  padding: 0.2em 0.4em;
}

.subtitle {
  font-weight: 100;
  text-align: center;
  margin: 0.8em auto 0 auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-top: 1.3em;
}

.button {
  margin-top: 1em;
  border: 2px solid var(--color-palette-3) !important;
}

.break {
  display: none;
}

@media (max-width: 768px) {
  .subtitle {
    max-width: 18em;
  }

  .break {
    display: inline;
  }
}