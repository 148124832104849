.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 0 6em 0;
  align-items: flex-start;
}

.title {
  width: 10em;
}

.subtitle {
  width: 25em;
  font-size: 1.2em;
  font-family: var(--global-font-family);
  font-weight: 100;
}

.container {
  width: 100%;
}

.button {
  margin-top: 3em;
}

.cover {
  background-position: 15%;
}

.back {
  margin-bottom: 2.2em;
}

@media only screen and (max-width: 992px) {
  .body {
    font-size: 0.8em;
  }

  .cover {
    background-position: 20%;
  }
}

@media only screen and (max-width: 768px) {
  .body {
    height: auto;
    padding: 4.2em;
  }

  .title {
    width: auto;
  }

  .subtitle {
    width: auto;
  }

  .buttons {
    text-align: left;
  }

  .cover {
    background-position: 0% 70%;
    background-size: cover;
  }
}
