.container {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
  animation: slots-empty-fade-in 1s;
}

@keyframes slots-empty-fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.centered {
  margin-top: 11.8em;
}

.info {
  text-align: center;
  max-width: 24em;
}

.message,
.number {
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  font-weight: 300;
  line-height: 140%;
}

.number {
  color: var(--color-palette-3);
  font-weight: 500;
  margin-left: 0.4em;
}

.button {
  margin-top: 2em;
}

@media (max-width: 576px) {

  .info {
    width: 86%;
  }
}
