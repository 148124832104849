.container {
  max-width: 30em;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6em;
}

.title {
  margin-top: 0.3em;
  margin-bottom: 0;
  text-align: center;
}

.header {
  margin-bottom: 0em;
}

.slots {
  margin-top: 4em;
}

.loader {
  margin-top: 5.8em;
}

.loader_init {
  margin-top: calc(100vh / 2 - 8em);
}


@media (max-width: 576px) {

  .container {
    padding-top: 2em;
  }

  .title {
    max-width: 11em;
  }

  .slots {
    margin-top: 3.5em;
  }
}