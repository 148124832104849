.buttons {
  display: flex;
  flex-direction: column;
  margin-top: 1.3em;
}

.button {
  margin-top: 1em;
  width: 12em;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  margin-bottom: 0;
}

.subtitle {
  text-align: center;
  max-width: 26em;
  font-weight: 300;
}

.back {
  margin: 1.2em auto 0 auto;
}

@media (max-width: 768px) {
  .body {
    padding: 3em;
  }
}