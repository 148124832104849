.container {
  animation: card-pay-option-fade-in 1s;
  width: 100%;
}

@keyframes card-pay-option-fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
} 

.button {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.4em;
}

.card_element,
.card_expiry,
.card_cvc {
  border-bottom: 2px solid #106A5C;
  padding: 0.8em 0;
}

.card_expiry,
.card_cvc {
  width: 46% !important;
}

.card_columns {
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {

  .wrapper {
    text-align: left;
  }
}
