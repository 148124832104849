.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 30em;
  margin: auto;
  padding-bottom: 6em;
}

.header_title {
  width: 9em;
  margin-top: 0.2em;
}

.header_subtitle {
  width: 25em;
  font-family: var(--global-font-family);
  font-weight: 100;
  font-size: 1.2em;
}

.plans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25em;
  margin-top: 5em;
}

.error {
  margin-top: 3em;
}

@media (max-width: 768px) {

  .header_title {
    width: 9em;
    margin-top: 1.5em;
  }

  .header_subtitle {
    width: 90%;
    font-size: 1em;
  }

  .container {
    width: 80%;
  }

  .plans {
    flex-direction: column;
    margin-top: 4em;
    width: 100%;
  }

  .plans > :not(:first-child) {
    margin-top: 4em;
  }
}

@media only screen and (max-width: 576px) {
  
  .header_title {
    width: 9em;
  }
}