.container {
  color: var(--color-palette-2);
  background-color: var(--color-palette-3);
  border: 1px solid var(--color-palette-3);
  font-family: var(--global-font-family);
  border-radius: 10em;
  position: fixed;
  bottom: 3em;
  display: flex;
  flex-direction: row;
  width: 11em;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 1em;
  box-sizing: border-box;
  animation-duration: 1.5s;
  animation-name: booking-action-slide;
  min-height: 2.4em;
}

.icon {
  margin: 3px 3px 0 5px;
  cursor: pointer;
}

.action {
  border-radius: 2em;
}

.rotation {
  animation: booking-action-spin 1.5s linear infinite;
}

@keyframes booking-action-slide {
  from {
    bottom: -3em;
  }

  to {
    bottom: 3em;
  }
}

@keyframes booking-action-spin {
  100% {
    transform: rotate(360deg);
  }
}
