.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 28em !important;
  padding-top: 3em;
  background-color: var(--color-palette-2);
}

.stepper {
  margin-bottom: 5em !important;
}

.body {
  max-width: 30em;
}

.description {
  min-height: 7em !important;
}

@media (max-width: 576px) {
  .container {
    padding: 0;
  }

  .body {
    max-width: 100%;
  }

  .description {
    max-width: 14em;
  }

  .stepper {
    margin-bottom: 5.5em !important;
  }
}
