.container {
  min-width: 4em;
  max-width: 4em;
  min-height: 6em;
  max-height: 6em;
  background-color: var(--color-palette-2);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--global-font-family);
  background-color: #007f6f;
  color: var(--color-palette-2);
  height: 2em;
}

.dayOfWeek {
  font-size: 0.9em;
  font-weight: 300;
  cursor: pointer;
}

.dayOfWeekSelected {
  color: var(--color-palette-1);
}

.day {
  font-size: 1.2em;
  font-weight: 500;
}

.month {
  font-size: 0.9em;
  margin-top: 0.2em;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  height: 4em;
  cursor: pointer;
}

.bodySelected {
  background-color: var(--color-palette-1);
  border-radius: 50%;
  height: 3.4em;
  width: 3.4em;
  margin-top: 0.3em;
  margin-left: 0.3em;
}
