.standbyRoot {
    display: flex;
    container-type: inline-size;
    flex-direction: column;
    padding: 0 33vw;
    gap: 3rem;

    @media (max-width: 768px) {
        padding: 0 2rem;
    }
}

.standbyRootLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.standbyHeaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    container-type: inline-size;
}

.standbyActionContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.standbyHeader {
    margin-bottom: 1rem;
    text-align: center;
}

.standbySubheader {
    display: flex;
    justify-content: center;
    margin-top: 0;
    text-wrap: balance;
    text-align: center;
    font-weight: 300;
    @media (max-width: 768px) {
        font-size: 1em;
    }
}

.standbyLocationTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 0.3rem;
}

.locationTextHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 1.6rem);
}

.standbyLocationAppointmentText {
    color: var(--color-palette-3);
    font-family: var(--global-font-family);
    font-size: small;
}

.standbyLocationHeader {
    font-family: var(--global-font-family);
    margin-top: 0;
    margin-bottom: 0;
    padding: 4px 8px;
    font-weight: 300;
    font-size: 1.2em;
    color: var(--color-palette-3);
    width: fit-content;
    background-color: var(--color-palette-1);
}

.standbyLocationMapLink {
    color: var(--color-palette-3);
    font-family: var(--global-font-family);
    font-size: 0.8rem;
}

.locationDivider {
    height: 2px;
    width: 100%;
    background-color: var(--color-palette-3);
}

.standbySlotContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 4rem;
    max-width: 320px;
    align-self: center;
    justify-content: center;
    width: 100%;
}

.standbySlotLocationContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.standbySlotButtonContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    width: 100%;
    row-gap: 0.5rem;
}

.standbySlotButton {
    appearance: none;
    border: none;
    background: transparent;
    border-radius: 12px;
    font-weight: 600;
    font-size: 1rem;
    min-height: 3rem;
    cursor: pointer;
    line-height: 28px;
    position: relative;
    overflow: hidden;
    height: 60px;
    font-family: var(--global-font-family);
}

.standbySlotButton:disabled {
    background-color: var(--color-blue-grey-1);
    opacity: 0.6;
    cursor: not-allowed;
}

.standbySlotButton[data-state="off"]:not(:disabled) {
    color: var(--color-palette-3);
}

.errorText {
    display: flex;
    justify-content: center;
    font-family: var(--global-font-family);
    color: var(--toastify-color-error);
}