.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-palette-4);
  padding: 5em 2em;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 3em;
}

.header {
  margin-top: 3.5em;
  max-width: 50em;
}

.title {
  font-size: 2.2em !important;
  max-width: 18em;
  line-height: 100%;
  text-align: center;
  color: white;
  margin: 0 auto;
}

.subtitle {
  text-align: center;
  color: white;
  margin-top: 1.5em;
  line-height: 140%;
}

@media (max-width: 1000px) {
  .title {
    font-size: 2em !important;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 3.5em 2em 4em 2em;
  }

  .title {
    font-size: 1.8em !important;
  }

  .subtitle {
    width: 90%;
    margin: 1.5em auto 0 auto;
  }

  .logo {
    height: 2.5em;
  }
}