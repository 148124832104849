.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30em;
  margin: auto;
  padding-bottom: 3em;
}

.header {
  width: 100%;
}

.title {
  margin-top: 1em;
}

.subtitle {
  width: 100%;
  font-family: var(--global-font-family);
  font-weight: 100;
  font-size: 1.2em;
}

.form {
  width: 100%;
  margin-top: 3em;
  margin-left: -0.6em;
  margin-bottom: 4em;
}

.buttons {
  text-align: right;
  width: 100%;
  margin-top: 2em;
}

.submit {
  min-width: 11em;
}

.card {
  border-bottom: 2px solid #106A5C;
  padding: 0.55em 0;
  margin-top: 1.5em;
}

.back {
  margin-bottom: 1.2em;
  align-self: flex-start;
}

.email {
  letter-spacing: 0.03em;
}

@media only screen and (max-width: 768px) {

  .container {
    height: auto;
    width: 80%;
  }

  .title {
    width: 10em;
    margin-top: 1.5em;
  }

  .subtitle {
    font-size: 1em;
  }

  .form {
    width: 100%;
    padding: 0;
  }

  .email {
    width: 100% !important;
    font-size: 0.8em;
  }

  .wrapper {
    text-align: left;
  }

  .submit {
    min-width: 10em !important;
  }

  .buttons {
    text-align: right;
  }

  .back {
    margin: 3em 0 0 0;
  }
}