.container {
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  box-sizing: border-box;
  background-color: #dde6d9;
  margin-top: 0.5em;
  padding: 0 0.8em 0 0.9em;
}

.title {
  font-size: 0.8em !important;
  line-height: 130% !important;
}

.description {
  font-weight: 100;
  line-height: 140%;
  font-size: 0.8em;
  display: block;
  margin-top: 0.5em;
  padding-right: 3em;
}

.icon {
  font-size: 0.75em;
}

.toggle {
  font-size: 0.8em;
  margin: 1em 0 1em 0;
}