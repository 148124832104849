.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26.1em;
  animation: slots-wrapper-fade-in 1s;
}

@keyframes slots-wrapper-fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
} 

.days {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  border: 2px solid var(--color-palette-3);
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  box-sizing: border-box;
}

.timeSlots {
  display: grid;
  row-gap: 0.5em;
  margin-top: 2em;
  width: 100%;
  box-sizing: border-box;
  width: auto;
}

.oneColumn {
  grid-template-columns: repeat(1, 6.5em);
}

.twoColumns {
  grid-template-columns: repeat(2, 6.5em);
}

.threeColumns {
  grid-template-columns: repeat(3, 6.5em);
}


@media (max-width: 576px) {

  .container {
    max-width: 18.1em;
  }
}
