.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 3em;
  background-color: var(--color-palette-2);
}

@media (max-height: 44em) {
  .container {
    justify-content: flex-start;
  }
}

.title {
  width: 6em;
}

.locations {
  margin-top: 3em;
  margin-bottom: 3em;
}

.option {
  margin-bottom: 1em;
}

.link,
.link:visited {
  text-decoration: underline;
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  font-size: 0.9em;
  margin: 2em 1.2em 0 0;
  display: inline-block;
  cursor: pointer;
}

.link:hover {
  opacity: 0.8;
}

.link_wrapper {
  width: 100%;
  text-align: right;
  display: none;
}

@media only screen and (max-width: 768px) {
  .container {
    height: auto;
  }

  .image  {
    background-position: 10% 62% !important;
  }

  .link {
    margin: 2em 0.3em 0 0;
  }
}