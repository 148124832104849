.container {
  background-color: var(--color-palette-1);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(-6deg);
}

.amount {
  margin-top: 0.1em;
  font-size: 1.13em;
}

.text {
  font-size: 0.7em;
}
