.container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  position: relative;  
}

.banner {
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-palette-3);
  color: white;
  font-family: var(--global-font-family);
  position: absolute;
  top: 0;
  left: 0;
}

.logo {
  position: absolute;
  height: 2.5em;
  width: auto;
  left: 1.6em;
  top: 1.4em;
}

.logo_when_banner {
  top: 4.4em
}

.logo path {
  fill: var(--color-palette-3);
}

.title {
  line-height: 110%;
}

.main {
  width: calc(50% - 1em);
  background-color: var(--color-palette-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 19em;
}

.form {
  width: 17em;
  margin-top: 1em;
}

.names {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name {
  width: 46% !important;
}

.email, .phone {
  width: 100%;
  margin-top: 1.5em;
}  

.button {
  margin-top: 3.2em;
  min-width: 10em;
}

.terms {
  max-width: 80%;
  margin-top: 1.6em;
  text-align: left !important;
}

.divider_vertical {
  height: 100vh !important;
  width: 2em;
}

.divider_horizontal {
  display: none;
}

.supporting {
  height: 100vh;
  width: calc(50% - 1em);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  width: 19em;
  font-size: 0.9em;
  text-align: left;
  margin-top: 0;
}

.header_when_banner {
  margin-top: 0;
}

.features {
  list-style: none;
  padding: 0;
  columns: 1;
}

.feature {
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  font-size: 2em;
  max-width: 10em;
  display: flex;
  line-height: 130%;
}

.features .feature:not(:first-child) {
  margin-top: 1em;
}

.feature::before {
  content: '';
  display: inline-block;
  height: 0.4em;
  min-height: 0.4em;
  width: 0.4em;
  min-width: 0.4em;
  background-image: url(./circle.svg);
  background-size: 100% 100%;
  margin-right: 0.5em;
  margin-top: 0.38em;
}

.smart_mouth {
  display: none;
  flex-direction: column;  
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 3.3em;
  background-color: var(--color-palette-4);
  width: 100%;
}

@keyframes smart_mouth_slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-25.2em);
  }
}

.smart_mouth_list {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  animation: smart_mouth_slide 5s linear infinite;
  padding-top: 0.1em;
}

.smart_mouth_list li {
  text-transform: uppercase;
  font-family: var(--global-font-family);
  color: var(--color-palette-3);
  font-weight: 400;
  min-width: 12em;
  margin: 0;
  padding: 0;
  font-size: 1.05em;
  letter-spacing: 0.08em;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header {
    margin: auto;
    text-align: center;
    max-width: 20em;
  }

  .banner {
    color: var(--color-palette-3);
    background-color: var(--color-palette-1);
    font-size: 0.9em;
  }

  .divider_vertical {
    display: none;
  }

  .divider_horizontal {
    display: inline-block;
    height: 1em;
  }

  .main {
    padding: 6em 0 6em 0;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .main_when_banner {
    padding-top: 8em;
  }

  .main_body {
    align-items: center;
  }

  .form {
    width: 22em;
    margin-top: 3em;
  }

  .button {
    margin: 3em auto 0 auto;
    min-width: 15em;
  }

  .supporting {
    width: 100%;
    height: auto;
    padding: 5em 0 5em 0;
  }

  .header {
    margin-top: 0.7em;
  }

  .logo {
    top: 2.25em;
    left: 1.5em;
  }

  .logo_when_banner {
    top: 4.25em;
  }

  .feature {
    font-size: 1.6em;
  }

  .terms {
    width: 25em;
    margin: 1.5em auto 0 auto;
    text-align: center !important;
  }

  .smart_mouth {
    display: flex;
  }
}

@media (max-width: 576px) {

  .main_body {
    width: 80%;
  }

  .form {
    width: 90%;
  }

  .header {
    width: 100%;
    margin-top: 1em;
    font-size: 1.2em;
  }

  .title {
    margin: 0;
  }

  .button {
    margin-top: 3em;
    font-size: 1.1em;
  }

  .main {
    padding-bottom: 5em;
  }

  .main_when_banner {
    padding-top: 8em;
  }

  .terms {
    max-width: 18em;
  }

  .feature {
    width: 10em;
    font-size: 1.4em;
  }
}