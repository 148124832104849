.container {
  display: inline-block;
  width: 4.3em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-palette-3);
  font-family: var(--global-font-family);
  font-weight: 300;
  cursor: pointer;
  width: auto;
}

.time {
  font-size: 1.2em;
  font-weight: 500;
}

.meridiem {
  font-size: 0.8em;
  margin-top: 0.15em;
  margin-left: 0.1em;
}

.selected {
  background-image: url(./squiggly.svg);
  background-repeat: no-repeat;
  background-size: 4.5em;
  background-position: bottom;
}