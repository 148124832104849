.container {
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin-left: 115px;
}

.wrapper {
  border-radius: 0%;
  display: flex;
  position: relative;
}