.text {
  font-family: var(--global-font-family);
  font-weight: 100;
  font-size: 1.2em;
  max-width: 16em;
  line-height: 130%;
  display: block;
  color: #d4532b;
}

.button {
  margin-top: 1.2em;
}