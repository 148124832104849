.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30em;
  margin: auto;
  padding-bottom: 3em;
}

.title {
  margin-top: 0em;
}

.subtitle {
  width: 100%;
  font-family: var(--global-font-family);
  font-weight: 100;
  font-size: 1.2em;
  margin-bottom: 2em;
}

.loader {
  margin-top: 0.66em;
  margin-left: 0;
  font-size: 1.2em;
}

.body {
  width: 100%;
  margin-bottom: 4em;
  margin-top: 1em;
}

.card_button {
  width: 14em;
}

.express {
  margin-top: 1em;
  width: 18em;
}

.buttons_loading {
  opacity: 0;
}

.buttons_loaded {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

@media only screen and (max-width: 768px) {

  .container {
    height: auto;
    width: 80%;
  }

  .title {
    width: 9.5em;
    margin-top: 1.5em;
  }

  .subtitle {
    font-size: 1em;
  }

  .body {
    width: 100%;
    padding: 0;
    font-size: 0.8em;
    padding-left: 0.5em;
  }
}
