.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--global-font-family);
  padding-bottom: 5em;

  @media (max-width: 768px) {
    padding-top: 2em;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;

  @media (max-width: 768px) {
    max-width: 20em;
  }
}

.header {
  max-width: 23em;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    margin-top: 1em;
  }
}

.title {
  margin-bottom: 0;
}

.description {
  margin: 0;
}

.subtitle {
  font-weight: 100;
  margin-top: 0.9em;
}

.org_name {
  font-weight: bold;
}

.discounted_price {
  font-weight: bold;
}

.confirm,
.skip {
  min-width: 12em !important;
  margin-top: 4em;
  align-self: flex-end;
  border: 2px solid var(--color-palette-3) !important;

  @media (max-width: 768px) {
    align-self: flex-start;
  }
}

.confirm {
  @media (max-width: 768px) {
    margin-top: 3.6em;
  }
}

.skip {
  margin-top: 0.8em;
}

.member {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  position: relative;
}

.remove {
  position: absolute;
  right: -0.6em;
  top: 0em;
  cursor: pointer;
}
.remove:hover {
  opacity: 0.9;
}

.remove_disabled,
.remove_disabled:hover {
  opacity: 0.3;
}

.first_name {
  width: 36%;
}

.last_name {
  width: 58%;
}

.add_button {
  color: var(--color-palette-3);
  border-bottom: 2px solid var(--color-palette-4);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  margin-top: 1.8em;
  padding-bottom: 0.1em;
  font-weight: 300;
}
.add_button:hover {
  opacity: 0.9;
}